// ///<reference path="../../td/jquery.d.ts"/> -> moved to tsconfig.json

class Persistence
{
    public static registerChildren(parent:any, prefix:string):void
    {
        //parent.find("input:text, input:radio, input:checkbox, select, textarea, input:password").each(function(idx, el)
        $("input:text, input:radio, input:checkbox, select, textarea").each(function(idx, el) // input:password
        {
            Persistence.register((<HTMLFormElement>el).name, prefix);
        });
   }
    public static registerAll(prefix:string):void
    {
        $("input:text, input:radio, input:checkbox, select, textarea").each(function(idx, el) // input:password
        {
            Persistence.register((<HTMLFormElement>el).name,prefix);
        });
   }
   public static register(name:string, prefix:string=""):void
    {
        //console.log("registering "+name);

        //--- retrieve existing value ---//
        var key:string = Persistence.getKey(name, prefix);
        var val:string = "";
        var el = $('[name="'+name+'"]');
        if (localStorage.getItem(key)!=null)
        {
            val = localStorage[key];
            el.val(val); //if (el[0] instanceof HTMLSelectElement) el.find("option").filter(function() { return $(this).text() == val;}).prop('selected', true);
        }

        //--- register events ---//
        var data = {"prefix":prefix, "name": name};
        el.keyup(data, Persistence.persist);
        el.change(data, Persistence.persist);
    }
    private static getKey(name:string, prefix:string=""):string
    {
        if (prefix!=null && prefix.length>0) return prefix+"-"+name;
        else return name;
    }
	public static hasValue(name:string, prefix:string=""):boolean
	{
        var key = Persistence.getKey(name, prefix);
		return key in localStorage;
	}
	public static getValue(name:string, prefix:string=""):string
	{
        var key = Persistence.getKey(name, prefix);
		return localStorage[key];
	}
	public static setValue(name:string, value:string, prefix:string=""):void
	{
        var key = Persistence.getKey(name, prefix);
		localStorage[key]=value;
	}
    private static persist(e):void
    {
        //console.log("writing: "+e.target.value);
        Persistence.setValue(e.data.name, e.target.value, e.data.prefix);
    }
}
